import { FaGithub, FaLinkedin, FaStrava } from "react-icons/fa";

const Header = () => {
  return (
    <header className="header">
      <a
        href="https://github.com/samkeshmiri"
        target="_blank"
        rel="noopener noreferrer"
        className="social-icon"
      >
        <FaGithub size={30} color="black" />
      </a>
      <a
        href="https://www.linkedin.com/in/samkeshmiri/"
        target="_blank"
        rel="noopener noreferrer"
        className="social-icon"
      >
        <FaLinkedin size={30} color="black" />
      </a>
      <a href="/">
        <img src="/s.png" alt="home" width={30}></img>
      </a>
      <a
        href="https://www.strava.com/athletes/23733930/"
        target="_blank"
        rel="noopener noreferrer"
        className="social-icon"
      >
        <FaStrava size={30} color="black" />
      </a>
      <a
        href="https://www.lensfrens.xyz/samthing"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src="/lens_logo_dark.svg" alt="lensfrens" width={40} height={30}></img>
      </a>
    </header>
  );
};

export default Header;
