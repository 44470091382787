import { useEffect } from "react";
import "./App.css";
import Bio from "./components/Bio";
import "kursor/dist/kursor.css";
import Kursor from "kursor";
import Header from "./components/Header";

const App = () => {
  useEffect(() => {
    new Kursor({
      type: 2,
      removeDefaultCursor: true,
      color: "#3B5249",
    });
  }, []);

  return (
    <div className="App">
      <Header />
      <Bio />
    </div>
  );
};

export default App;
