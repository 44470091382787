const Bio = () => {
  return (
    <section className="Bio">
      <h1>Hey, I'm Sam!</h1>
      <p>
        <br></br>I'm helping build the future of finance with{" "}
        <a
          className="styled-link"
          href="https://avara.xyz"
          target="_blank"
          rel="noopener noreferrer"
        >
          Avara
        </a>{" "}
        (fka Aave Labs).
      </p>
    </section>
  );
};

export default Bio;
